import * as Sentry from '@sentry/react';
import { history } from '@/store';
import { BrowserTracing } from '@sentry/tracing';

function initializeSentry() {
  if (!ENV_CONFIG.SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: ENV_CONFIG.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // Only report transactions (used for performance monitoring) for 10% of sessions
    tracesSampleRate: 0.1,
    environment: ENV_CONFIG.SENTRY_ENVIRONMENT,
  });
}

export { initializeSentry };
