import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function TwitterIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M15.3332 2.00011C14.6948 2.45043 13.9879 2.79485 13.2398 3.02011C12.8383 2.55845 12.3047
        2.23124 11.7112 2.08273C11.1177 1.93422 10.4928 1.97158 9.92122 2.18975C9.34961 2.40792 8.8588
        2.79638 8.51516 3.30259C8.17152 3.8088 7.99164 4.40834 7.99984 5.02011V5.68678C6.82826 5.71716
        5.66735 5.45732 4.62051 4.93041C3.57367 4.4035 2.67338 3.62587 1.99984 2.66678C1.99984 2.66678
        -0.666829 8.66678 5.33317 11.3334C3.96019 12.2654 2.32461 12.7327 0.666504 12.6668C6.6665 16.0001
        13.9998 12.6668 13.9998 5.00011C13.9992 4.81441 13.9814 4.62918 13.9465 4.44678C14.6269 3.77577
        15.1071 2.92859 15.3332 2.00011V2.00011Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default TwitterIcon;
