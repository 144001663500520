import { Typography } from '@mui/material';

import { Container, SubtleLoader } from './styled';
import { LoadingProps } from './types';

function Loading({ text, sx = {} }: LoadingProps) {
  return (
    <Container sx={sx}>
      <SubtleLoader />
      {text && <Typography variant="h4">{text}</Typography>}
    </Container>
  );
}

export default Loading;
