import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import CircleFilledIcon from '@/components/Icons/CircleFilledIcon';
import CircleIcon from '@/components/Icons/CircleIcon';

import { PermissionOptionsEnum } from '../types';
import ModelList from './ModelList';
import { PermissionsProps } from './types';

function Permissions({
  onChange,
  selectedOption,
  onModelSelect,
  selectedModelIds,
  onAllModelsSelect,
  selectedAllModels,
}: PermissionsProps) {
  return (
    <FormControl sx={{ width: '100%' }}>
      <RadioGroup sx={{ gap: 2 }} value={selectedOption} onChange={onChange}>
        <FormControlLabel
          value={PermissionOptionsEnum.InvokeAllModels}
          control={<Radio checkedIcon={<CircleFilledIcon />} icon={<CircleIcon />} />}
          sx={{ gap: 1 }}
          label={<Typography variant="h5">Can manage and call all workspace models</Typography>}
        />
        <FormControlLabel
          value={PermissionOptionsEnum.CanInvokeModels}
          control={<Radio checkedIcon={<CircleFilledIcon />} icon={<CircleIcon />} />}
          sx={{ gap: 1 }}
          label={<Typography variant="h5">Can only call specific models</Typography>}
        />
      </RadioGroup>
      {selectedOption === PermissionOptionsEnum.CanInvokeModels && (
        <ModelList
          onModelSelect={onModelSelect}
          selectedModelIds={selectedModelIds}
          onAllModelsSelect={onAllModelsSelect}
          selectedAllModels={selectedAllModels}
        />
      )}
    </FormControl>
  );
}

export default Permissions;
