import { Link, Stack, buttonClasses } from '@mui/material';
import MuiMenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';

import BaseAppLink from '@/components/AppLink';
import { defaultPalette } from '@/theme';

const GLOBAL_HEADER_HEIGHT = 57;

const Header = styled(Stack, { shouldForwardProp: (prop) => prop !== 'padding' })<{
  padding?: 'default' | 'small';
}>(({ padding = 'default', theme }) => ({
  background: `${defaultPalette.background.default}`,
  borderBottom: `1px solid ${defaultPalette.border.default}`,
  padding: theme.spacing(2, 1, 2, 2),
  height: GLOBAL_HEADER_HEIGHT,
  flexShrink: 0,

  [`& .${buttonClasses.contained}:hover`]: {
    color: `${theme.palette.text.emphasis} !important`, // patch to override the black text on link hover
  },
}));

Header.defaultProps = {
  alignItems: 'center',
  justifyContent: 'space-between',
  direction: 'row',
};

const NavLink = styled(Link, { shouldForwardProp: (prop) => prop !== 'active' })<{
  active?: boolean;
}>(({ theme, active }) => ({
  color: active ? theme.palette.success.main : theme.palette.text.default,
}));

NavLink.defaultProps = {
  underline: 'none',
  variant: 'display2',
  active: false,
};

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: 0,
  gap: theme.spacing(1),
  minHeight: 'auto',
}));

const AppLink = styled(BaseAppLink)(({ theme }) => ({
  padding: theme.spacing(0.75, 1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100%',

  '&:hover': {
    color: theme.palette.text.default,
    textDecoration: 'none',
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  padding: theme.spacing(0.75, 1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100%',
  textDecoration: 'none',
  color: theme.palette.text.default,

  '&:hover': {
    color: theme.palette.text.default,
    textDecoration: 'none',
  },
}));

AppLink.defaultProps = {
  color: 'inherit',
  underline: 'none',
};

export { Header, GLOBAL_HEADER_HEIGHT, NavLink, MenuItem, AppLink, StyledLink };
