import { useState } from 'react';

import { Divider, IconButton, Menu, Typography } from '@mui/material';

import BookIcon from '@/components/Icons/BookIcon';
import BookOpenIcon from '@/components/Icons/BookOpenIcon';
import CodeIcon from '@/components/Icons/CodeIcon';
import HelpIcon from '@/components/Icons/HelpIcon';
import MailIcon from '@/components/Icons/MailIcon';
import MessageIcon from '@/components/Icons/MessageIcon';
import TwitterIcon from '@/components/Icons/TwitterIcon';
import { useAsOperator } from '@/hooks';
import { links } from '@/localization';
import { usePylon } from '@bolasim/react-use-pylon';

import { MenuItem, StyledLink } from '../../../styled';

function HelpMenu() {
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isHelpMenuOpen = Boolean(helpMenuAnchorEl);
  const asOperator = useAsOperator();
  const { show } = usePylon();

  function openHelpMenu(event: React.MouseEvent<HTMLElement>): void {
    setHelpMenuAnchorEl(event.currentTarget);
  }

  function handleCloseHelpMenu(): void {
    setHelpMenuAnchorEl(null);
  }

  return (
    <>
      <IconButton
        onClick={openHelpMenu}
        aria-controls={isHelpMenuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isHelpMenuOpen ? 'true' : undefined}
      >
        <HelpIcon />
      </IconButton>
      <Menu
        anchorEl={helpMenuAnchorEl}
        open={isHelpMenuOpen}
        onClose={handleCloseHelpMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography variant="h6" color="secondary" sx={{ p: 1 }}>
          Help
        </Typography>
        <MenuItem onClick={show}>
          <StyledLink onClick={show} href="#">
            <MessageIcon color="secondary" /> Chat with support
          </StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink
            href={links.basetenDocs}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <BookIcon color="secondary" /> Documentation
          </StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink
            href={links.basetenDocsAPIReference}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <CodeIcon color="secondary" /> API documentation
          </StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink
            href={links.trussDocsHome}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <BookOpenIcon color="secondary" /> Truss documentation
          </StyledLink>
        </MenuItem>
        <Divider sx={{ mx: -1, my: 1 }} />
        {!asOperator && (
          <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }}>
            <StyledLink href={links.basetenModelLibrary}>
              <BookOpenIcon color="secondary" /> Model library
            </StyledLink>
          </MenuItem>
        )}
        <MenuItem>
          <StyledLink
            href={links.sendFeedbackGoogleForm}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <MailIcon color="secondary" /> Send feedback
          </StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink
            href={links.basetenTwitter}
            onClick={handleCloseHelpMenu}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon color="secondary" /> Follow @basetenco
          </StyledLink>
        </MenuItem>
      </Menu>
    </>
  );
}

export default HelpMenu;
