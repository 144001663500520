enum Tabs {
  General = 'general',
  Members = 'members',
  Billing = 'billing',
  SystemPackages = 'system_packages',
  PythonEnvironment = 'python_environment',
  Secrets = 'secrets',
  Integrations = 'integrations',
  ApiKeys = 'api_keys',
}

export { Tabs };
