import { Link } from '@mui/material';

import AppLink from '@/components/AppLink';
import BrandMarkIcon from '@/components/Icons/BrandMarkIcon';
import BasetenLogo from '@/components/Logo';
import { links } from '@/localization';
import { BasetenPageEnum } from '@/routes';
import { ColorScale } from '@/theme/colors';
import { getBorder } from '@/theme/utils';
import { trackClick } from '@/utils/analytics/google';

import { LogoProps } from './types';

function Logo({ variant }: LogoProps) {
  const goToWorkspace = (label: string) => () => {
    trackClick('baseten logo', label);
  };

  switch (variant) {
    case 'icon':
      return (
        <AppLink
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
            border: (theme) => getBorder(`${theme.palette.background.default}`),
            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
            width: 32,
            height: 32,

            '&:hover': {
              background: (theme) => `${theme.palette.background.success}`,
              border: (theme) => getBorder(`${theme.palette.border.success}`),
              color: ColorScale.green[600],
            },
          }}
          onClick={goToWorkspace('Return to workspace')}
          color="text.success"
          data-cy="go-to-home"
          page={BasetenPageEnum.Home}
        >
          <BrandMarkIcon sx={{ height: 20 }} />
        </AppLink>
      );

    case 'logo':
      return (
        <Link href={links.basetenHome} sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
          <BasetenLogo height={24} />
        </Link>
      );

    default:
      return null;
  }
}

export default Logo;
