import has from 'lodash/has';

import { ENVIRONMENTS } from './types';

export * from './deploymentStatus';
export { USER_INPUT_DEBOUNCE_DELAY };

export const APP_ENV = ENV_CONFIG.APP_ENV.toUpperCase() as ENVIRONMENTS;
if (!has(ENVIRONMENTS, APP_ENV)) {
  // eslint-disable-next-line no-alert
  alert(
    `Invalid environment name "${APP_ENV}". If you've created a new environment, add it to ENVIRONMENTS in index.js.`,
  );
  throw Error(`Invalid environment name ${APP_ENV}`);
}

const NON_DEV_ENVS = [ENVIRONMENTS.STAGING, ENVIRONMENTS.PRODUCTION];
export const IS_DEV_MODE = !NON_DEV_ENVS.includes(APP_ENV);

export const OPERATING_WORKFLOW_REQUEST_HEADER = 'X-OPERATING-WORKFLOW-ID';

/**
 * Time to wait for debouncing user input
 */
const USER_INPUT_DEBOUNCE_DELAY = 300;

export const FREE_CREDIT_WARNING_THRESHOLD = 500;

export const EXPLORE_PAGE_URL = 'https://www.baseten.co/library/';

export const WORKSPACE_NAME_FALLBACK = 'My workspace';

export const MAX_PINNED_ENTITIES = 6;
