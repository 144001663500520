import { ChangeEvent, useEffect, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitleWithX from '@/components/DialogTitleWithX';
import { ApiKeyType } from '@/graphql';
import { getBorder } from '@/theme/utils';
import { useSnackbar } from 'notistack';

import {
  CreateUserApiKeyMutation,
  CreateWorkspaceApiKeyMutation,
} from '../__generated__/mutations.generated';
import { MainOptionsEnum, PermissionOptionsEnum } from '../types';
import MainOptions from './MainOptions';
import Permissions from './Permissions';
import { OptionsDialogProps } from './types';

const mapTitleToStep: Record<MainOptionsEnum, string> = {
  [MainOptionsEnum.Personal]: 'Create API key',
  [MainOptionsEnum.Workspace]: 'Create workspace API key',
};

const mapDescriptionToStep: Record<MainOptionsEnum, string> = {
  [MainOptionsEnum.Personal]:
    'Choose whether you want to create an API key associated with your personal account or with your workspace.',
  [MainOptionsEnum.Workspace]: 'Select the permissions you want to assign to this API key.',
};

function OptionsDialog({
  onClose,
  onCreate,
  isOpen,
  isLoading,
  createUserApiKey,
  createWorkspaceApiKey,
}: OptionsDialogProps) {
  const [selectedOption, setSelectedOption] = useState<MainOptionsEnum>(MainOptionsEnum.Personal);
  const [selectedPermissionOption, setSelectedPermissionOption] = useState<PermissionOptionsEnum>(
    PermissionOptionsEnum.InvokeAllModels,
  );
  const [step, setStep] = useState<MainOptionsEnum>(MainOptionsEnum.Personal);
  const [selectedModelIds, setSelectedModelIds] = useState<string[]>([]);
  const [selectedAllModels, setSelectedAllModels] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isOpen) {
      // reset dialog state
      setSelectedOption(MainOptionsEnum.Personal);
      setSelectedPermissionOption(PermissionOptionsEnum.InvokeAllModels);
      setStep(MainOptionsEnum.Personal);
      setSelectedModelIds([]);
      setSelectedAllModels(false);
    }
  }, [isOpen]);

  function handleMainOptionChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedOption((event.target as HTMLInputElement).value as MainOptionsEnum);
  }

  function handlePermissionOptionChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedPermissionOption((event.target as HTMLInputElement).value as PermissionOptionsEnum);
  }

  function goToNextStep() {
    setStep(MainOptionsEnum.Workspace);
  }

  async function handleCreateApiKey() {
    try {
      let result;
      let apiKeyType;

      if (step === MainOptionsEnum.Personal) {
        if (selectedOption === MainOptionsEnum.Personal) {
          result = await createUserApiKey();
          apiKeyType = ApiKeyType.Personal;
        }
      } else if (selectedPermissionOption === PermissionOptionsEnum.InvokeAllModels) {
        apiKeyType = ApiKeyType.CreatorServiceAccount;
        result = await createWorkspaceApiKey({
          variables: {
            apiKeyType,
          },
        });
      } else {
        apiKeyType = selectedAllModels
          ? ApiKeyType.InvokeAllModelsServiceAccount
          : ApiKeyType.InvokeAllowedModelsServiceAccount;

        result = await createWorkspaceApiKey({
          variables: {
            allowedModelIds: selectedModelIds,
            apiKeyType,
          },
        });
      }

      const createdApiKey =
        selectedOption === MainOptionsEnum.Personal
          ? (result.data as CreateUserApiKeyMutation)?.createUserApiKey.textKey
          : (result.data as CreateWorkspaceApiKeyMutation)?.createWorkspaceApiKey.textKey;

      onCreate(createdApiKey, apiKeyType);
      onClose();
    } catch (e) {
      enqueueSnackbar('The secret could not be created. Please try again.', { variant: 'error' });
    }
  }

  const isCreateButton =
    selectedOption === MainOptionsEnum.Personal || step === MainOptionsEnum.Workspace;
  const isCreateButtonDisabled =
    step === MainOptionsEnum.Workspace &&
    selectedPermissionOption === PermissionOptionsEnum.CanInvokeModels &&
    !selectedAllModels &&
    !selectedModelIds.length;

  const showSelectedCount =
    selectedPermissionOption === PermissionOptionsEnum.CanInvokeModels &&
    selectedModelIds.length > 0;

  return (
    <>
      <Dialog
        PaperProps={{
          sx: { width: 500 },
        }}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitleWithX onClose={onClose}>{mapTitleToStep[step]}</DialogTitleWithX>
        <DialogContent>
          <Typography variant="body1" sx={{ my: 2 }}>
            {mapDescriptionToStep[step]}
          </Typography>
          {step === MainOptionsEnum.Personal ? (
            <MainOptions selectedOption={selectedOption} onChange={handleMainOptionChange} />
          ) : (
            <Permissions
              selectedOption={selectedPermissionOption}
              onChange={handlePermissionOptionChange}
              onModelSelect={setSelectedModelIds}
              selectedModelIds={selectedModelIds}
              selectedAllModels={selectedAllModels}
              onAllModelsSelect={setSelectedAllModels}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            background: theme.palette.background.subtle,
            borderTop: getBorder(theme.palette.border.default),
            justifyContent: showSelectedCount ? 'space-between' : 'flex-end',
          })}
        >
          {showSelectedCount && (
            <Typography variant="body2" color="text.subtle">
              {selectedModelIds.length} selected
            </Typography>
          )}
          <Stack direction="row" alignItems="center" gap={1}>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="success"
              onClick={isCreateButton ? handleCreateApiKey : goToNextStep}
              data-cy="create-api-key"
              disabled={isCreateButton && isCreateButtonDisabled}
            >
              {isCreateButton ? 'Create API key' : 'Next'}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OptionsDialog;
